<template>
  <div>
    <template v-if="dataList.length > 0">
      <b-card-body>
        <b-input-group>
          <b-form-input
            v-model="keyword"
            placeholder="Plaka, Şase, Araç Kodu..."
            @keydown.enter="keywordSearch"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="keywordSearch"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
      >
        <template #cell(title)="data">
          <div class="text-muted font-small-2">
            {{ data.item.arackodu }}
          </div>
          <div>
            {{ data.item.title }}
          </div>
          <div class="text-warning font-small-2">
            <strong>Şase:</strong> {{ data.item.chasis }}
          </div>
          <div class="text-danger font-small-2">
            <strong>Ruhsat Seri No:</strong> {{ data.item.license_number }}
          </div>
          <hr>
          <div class="text-muted font-small-2">
            {{ data.item.notes }}
            <div class="font-italic">
              {{ data.item.username }}
            </div>
          </div>
        </template>
        <template #cell(licence_plate)="data">
          <div>
            {{ data.item.licence_plate }}
          </div>
          <b-button
            variant="flat-danger"
            size="sm"
            pill
            :disabled="!data.item.licenseFile"
            :href="baseURL + '/media/rentals/' + data.item.licenseFile"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" /> Ruhsat
          </b-button>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
              @click="setModalData(data.item)"
            >
              <FeatherIcon icon="UploadIcon" />
              Dosya Yükle
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </template>
    <template v-else>
      <b-card-body>
        <b-alert
          class="mt-1"
          show
          variant="success"
        >
          <div class="alert-body text-center">
            <FeatherIcon icon="CheckCircleIcon" />
            Bekleyen poliçe bulunmamaktadır.
          </div>
        </b-alert>
      </b-card-body>
    </template>
    <b-modal
      v-model="uploadModal"
      title="Dosya Yükle"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <b-list-group>
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Araç Bilgisi
          </div>
          <div>
            <div class="font-small-2">
              {{ modalData.arackodu }}
            </div>
            <div>
              {{ modalData.title }}
            </div>
            <div class="font-small-2 text-warning">
              {{ modalData.chasis }}
            </div>
            <div class="font-small-2 text-muted">
              {{ modalData.licence_plate }}
            </div>
            <div class="font-small-2 text-muted">
              <strong>Ruhsat Seri No: </strong> {{ modalData.license_number }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <pre-loading
        v-if="uploading"
        class="my-2"
      />
      <template v-else>
        <b-form-group
          class="mt-1"
          label="Dosya"
          label-for="upload_file"
        >
          <b-form-file
            id="upload_file"
            v-model="modalData.upload_file"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosyayı buraya bırakın..."
            browse-text="Dosya Seçin"
          />
        </b-form-group>
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="!modalData.upload_file"
            @click="submitForm"
          >
            <FeatherIcon icon="UploadIcon" />
            Dosya Yükle
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCardBody,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BPagination,
  BCardFooter,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BFormGroup,
  BFormDatepicker,
  BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormGroup,
    BFormDatepicker,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    PreLoading,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      uploadModal: false,
      modalData: {
        id: null,
        arackodu: null,
        title: null,
        chasis: null,
        notes: null,
        sdate: null,
        edate: null,
        licence_plate: null,
        license_number: null,
        upload_file: null,
        id_com_rental_stocks: null,
        id_com_rental_document_types: 7,
      },
      keyword: null,
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'title',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'licence_plate',
          label: 'PLAKA',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        where: {
          'com_rental_insurances.id_com_rental_insurance_types': 3,
          'com_rental_insurances.id_com_rental_documents': null,
        },
        limit: 20,
        start: 0,
        licenseFile: true,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalInsurances/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalInsurances/dataCounts']
    },
    saveData() {
      return this.$store.getters['rentalInsurances/dataSaveStatus']
    },
    uploading() {
      return this.$store.getters['rentalInsurances/uploading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    setModalData(data) {
      this.modalData = {
        id: data.id,
        arackodu: data.arackodu,
        title: data.title,
        chasis: data.chasis,
        licence_plate: data.licence_plate,
        license_number: data.license_number,
        notes: data.notes,
        sdate: null,
        edate: null,
        id_com_rental_stocks: data.id_com_rental_stocks,
        id_com_rental_document_types: 7,
        upload_file: null,
      }
      this.uploadModal = true
    },
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_rental_stocks.arackodu': this.keyword,
        'com_rental_stocks.licence_plate': this.keyword,
        'com_rental_stocks.chasis': this.keyword,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('rentalInsurances/getDataList', this.dataQuery)
    },
    submitForm() {
      this.$store.dispatch('rentalInsurances/saveUpload', this.modalData)
        .then(res => {
          if (res) {
            this.dataQuery.or_like = {}
            this.getDataList()
            this.uploadModal = false
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
